/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import EventCardMini from "../event-card-mini"

const RelatedEvents = ({ events }) => {
  return (
    <React.Fragment>
      <h5 sx={{}}>Related Events</h5>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: [
            "1fr",
            "1fr",
            "1fr 1fr",
            "1fr 1fr",
            "1fr 1fr 1fr",
          ],
          gap: "25px",
        }}
      >
        {events.map((event, index) => (
          <EventCardMini type="secondary" key={index} event={event} />
        ))}
      </div>
    </React.Fragment>
  )
}

export default RelatedEvents
