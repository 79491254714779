/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import StoryMain from "./story-main"
import RelatedEvents from "./related-events"
import Container from "../container"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedStories from "../featured-stories"

const StoryDetail = ({ story }) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        featuredStoriesSequence {
          orderItems {
            contentful_id
            author {
              name
            }
            title
            images {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <StoryMain story={story} />
      <Container>
        <div
          sx={{
            width: "100%",
            height: "1px",
            backgroundColor: "#a5a5a5",
            mb: "38px",
          }}
        ></div>
        {story.events && <RelatedEvents events={story.events} />}
        <div
          sx={{
            my: "50px",
          }}
        >
          <div sx={{ mb: "25px" }}>
            <h5>More Stories</h5>
          </div>
          <FeaturedStories
            stories={data.contentfulSite.featuredStoriesSequence.orderItems.filter(
              featuredStory =>
                featuredStory.contentful_id !== story.contentful_id
            )}
          />
        </div>
      </Container>
    </React.Fragment>
  )
}

export default StoryDetail
