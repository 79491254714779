/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import StoryDetail from "../components/story-detail"
import { useLocalStorage } from "react-use"
import { useHistory } from "../hooks/use-history"

const StoryTemplate = ({ data }) => {
  const story = data.story
  useHistory("story-history", story.contentful_id)
  return (
    <React.Fragment>
      {story && (
        <SEO
          title={story.title}
          {...(story.teaser &&
            story.teaser.teaser && { description: story.teaser.teaser })}
          {...(story.images &&
            story.images[0] && { imageUrl: story.images[0].file.url })}
        />
      )}
      {story && <StoryDetail story={story} />}
    </React.Fragment>
  )
}

export default StoryTemplate

export const query = graphql`
  query($id: String!) {
    story: contentfulEditorialStory(contentful_id: { eq: $id }) {
      title
      contentful_id
      teaser {
        teaser
      }
      author {
        image {
          file {
            url
          }
        }
        name
        description {
          description
        }
      }
      body {
        json
      }
      images {
        file {
          url
        }
        description
      }
      events {
        eventStartDate
        eventEndDate
        eventImage {
          file {
            url
          }
        }
        eventName
        organization {
          name
        }
        contentful_id
        discipline {
          disciplineName
        }
      }
    }
  }
`
