/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { renderLineBreak } from "../../helpers"
import RichText from "../rich-text"
import SwipeableView from "../swipeable-view"
import Container from "../container"

const StoryMain = ({ story }) => {
  return (
    <React.Fragment>
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row"],
          position: "relative",
          pb: "30px",
          mb: ["20px", "20px", "80px"],
        }}
      >
        <div sx={{ width: ["100%", "100%", "44%"] }}>
          {story.images && story.images[0] && (
            <React.Fragment>
              <img
                sx={{
                  width: "100%",
                  height: ["280px", "280px", "100vh"],
                  position: ["initial", "initial", "sticky"],
                  top: 0,
                  objectFit: "cover",
                }}
                alt={story.images[0].description}
                src={story.images[0].file.url}
              />
              {story.images[0].description && (
                <div
                  sx={{
                    display: ["block", "block", "none"],
                    px: 20,
                    fontSize: "12px",
                    color: "swatches.secondary.darkTan",
                  }}
                >
                  {story.images[0].description}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <div sx={{ maxWidth: ["unset", "unset", "825px"] }}>
          <div
            sx={{
              pl: [20, 20, "130px"],
              pr: theme => theme.spacing.horizontal,
              py: ["15px", "15px", "84px"],
            }}
          >
            <h2 sx={{ fontSize: ["48px", "48px", "66px"], mb: "25px" }}>
              {story.title}
            </h2>

            {story.author && story.author.name && (
              <h6 sx={{ fontSize: "15px", mb: "25px" }}>
                By {story.author.name}
              </h6>
            )}
            {story.teaser && (
              <h3 sx={{ fontSize: ["24px", "24px", "36px"] }}>
                {renderLineBreak(story.teaser.teaser)}
              </h3>
            )}
            <RichText data={story.body} />
            {story.author && (
              <div
                sx={{
                  maxWidth: "600px",
                  mt: "50px",
                  mb: 0,
                  pb: 0,
                }}
              >
                <div
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "max-content 1fr",
                    columnGap: ["15px", "15px", "35px"],
                  }}
                >
                  <img
                    alt={story.author.name}
                    src={story?.author?.image?.file?.url + "?w=125&h=125"}
                  />
                  <div sx={{ py: "3px" }}>
                    <h6>{story.author.name}</h6>
                    <div sx={{ display: ["none", "none", "block"] }}>
                      {story.author.description &&
                        renderLineBreak(story.author.description.description)}
                    </div>
                  </div>
                </div>
                <div
                  sx={{
                    display: ["block", "block", "none"],
                    mt: "20px",
                  }}
                >
                  {story.author.description &&
                    renderLineBreak(story.author.description.description)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {story.images && story.images.length > 1 && (
        <Container>
          <div>
            <h5>More Images from This Story</h5>
            <SwipeableView
              visibleElements={[1, 1, 1, 1, 1, 1, 1]}
              bleeding={[100, 50, 0, 0, 0, 0, 0]}
            >
              {story.images.slice(1).map(image => (
                <div
                  sx={{
                    width: "100%",
                    px: "1px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt=""
                    src={image.file.url}
                    sx={{
                      width: "100%",
                      objectFit: "contain",
                      maxHeight: "90vh",
                    }}
                  />
                  {image.description && (
                    <div sx={{ width: "100%", my: "15px" }}>
                      {image.description}
                    </div>
                  )}
                </div>
              ))}
            </SwipeableView>
          </div>
        </Container>
      )}
    </React.Fragment>
  )
}

export default StoryMain
